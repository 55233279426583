/*================================================================
[Table of contents]
==================================================================*/
/*
	1. Include bootstrap 5 beta default
	2. General styles and resets
	3. Header / #header
	4. Buttons / .btn
	5. Backgrounds colors
	6. Parallax /.parallax
	7. Navigation /.navbar-nav
	8. Banner /.banner
	9. Services /.services
	10. About us /.about
	11. Counter /.count-block
	12. our work /.portfolio
	13. Carousel /.owl-carousel
	14. Home blog /.newsnblog 
	15. Price table /.price-table 
	16. Contact /.contact 
	17. Pages
	18.Footer /#footer
==================================================================*/

// ==================================================================
// SCSS IMPORTS
// ==================================================================
//import google fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap");

// Bootstrap
@import "../../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "seetings/main";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

/*================================================================
1. General styles and resets
==================================================================*/
// ---  BASE  ---
@import "base/base";

// ---  PRELOADER ---
@import "components/preloader";
@import "components/pagination";
@import "components/content-search";
@import "components/recent-article";
@import "components/social-icons";

// ---  HEADER ---
/*================================================================
3. Header /#header
==================================================================*/
@import "layout/header";

// ---  SIDEBAR  ---
/*================================================================
3. Sidebar /#sidebar
==================================================================*/
@import "layout/sidebar";

// ---  COMPONENTS  ---
/*================================================================
3. Swiper Slide /.swiper-slide
==================================================================*/
@import "components/swiper-slide";

/*================================================================
3. MasonryJs /.masonry 
==================================================================*/
@import "components/masonry";
/*================================================================
3. buttons /.btn 
==================================================================*/
@import "components/buttons";

/*================================================================
3. Custom form /.custom-form 
==================================================================*/
@import "components/custom-form";

/*================================================================
3. Accordion Style /.accordion
==================================================================*/
@import "components/accordion";

/*================================================================
3. Hero showcase /.hero-showcase
==================================================================*/
@import "components/hero-showcase";

/*================================================================
3. Canvas Sidebar /.canvas-sidebar
==================================================================*/

@import "components/progress";
@import "components/navbar";
@import "components/card";
@import "components/perfact-scrollbar";
@import "components/timelines";
@import "components/actions-bar";
@import "components/ql-editor";
@import "components/croppie";
@import "components/datatable";
@import "components/cusom-tab";

// // ---  Page ---
/*================================================================
18.Page /#page
==================================================================*/
@import "page/500";
@import "page/404";
@import "page/coming-soon";
@import "page/login-register";

// // ---  FOOTER ---
/*================================================================
18.Footer /#footer
==================================================================*/
@import "layout/footer";

.rdp {
  --rdp-cell-size: 36px;
  --rdp-accent-color: var(--flm-primary);
  --rdp-background-color: rgba(var(--flm-primary), 0.1);
  --rdp-accent-color-dark: darken(var(--flm-primary), 5%);
  --rdp-background-color-dark: darken(var(--flm-primary), 5%);
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
}

//aria-labelledby="react-media-library-modal"
.modal-backdrop {
  z-index: 9999;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.75);
}
div[aria-labelledby="react-media-library-modal"] {
  z-index: 9999;
  .modal-dialog {
    box-shadow: 0 16px 16px -10px rgb(34 47 62 / 15%),
      0 0 40px 1px rgb(34 47 62 / 15%);
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    margin: 0;
    .modal-content {
      border-radius: 3px;
      border: 1px solid $border-color;
      height: 100%;
      .tab-content {
        height: 100%;
      }
      .modal-header {
        padding-bottom: 0;
        border-bottom: 0;
        .close {
          background-color: transparent;
          border: none;
          opacity: 1;
          text-shadow: none;
          @include font-size(1.5rem);
          .sr-only {
            display: none;
          }
        }
      }
      .modal-body {
        position: static;
        padding-top: 0;
        .pt-3 {
          padding-top: 0 !important;
        }
        .alert-secondary {
          background-color: rgba($primary, 0.1);
          border: 1px solid rgba($primary, 0.12);
        }
        .nav-tabs {
          position: absolute;
          left: 15px;
          top: 32px;
          border: 0;
          .nav-link {
            border: 0;
            background-color: transparent;
            @include font-size(0.8375rem);
            font-weight: 600;
            padding: 0;
            & + .nav-link {
              margin-left: 10px;
            }
          }
        }
        .tab-pane {
          h3 {
            @include font-size(1rem);
          }
          > ul > .list-group-item {
            .badge {
              font-weight: 500;
              color: inherit;
            }
          }
          .row {
            // display: grid;
            // grid-gap: 5px;
            // grid-template-columns: repeat(6, 100px);
            // grid-template-rows: 100px 100px 100px;
            // grid-auto-flow: column;
            margin: -0.25rem;
            flex-wrap: wrap;

            > .mb-3 {
              padding: 0.25rem;
              width: 100px;
              flex: 0 0 100px;
            }
          }
          .text-right {
            position: fixed;
            right: 15px;
            bottom: 15px;
            width: calc(100% - 30px) !important;
            text-align: right;
          }
        }

        .card {
          padding: 4px;
          border-radius: 1px;
          border: 1px solid transparent;
          background-color: transparent;
          .card-img-top {
            border: 0;
            border-radius: 0;
            box-shadow: 0 0 2px rgba($black, 0.24);
            height: auto !important;
            width: auto !important;
            max-height: 100px;
          }
          .card-body {
            display: none;
          }
          .list-group {
            > .list-group-item:not(:nth-child(1)) {
              display: none;
            }
            > .list-group-item {
              padding: 5px 0 0;
              border: none;
              font-size: 0.75rem;
              background-color: transparent;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:hover {
            border-color: rgba($primary, 0.2);
            background-color: rgba($primary, 0.0875);
            .card-img-top {
              box-shadow: none;
            }
          }
          &.bg-primary {
            border-color: rgba($primary, 0.5);
            background-color: rgba($primary, 0.0875) !important;
            .card-img-top {
              box-shadow: none;
            }
          }
        }
      }
    }
    .pagination .page-item .page-link {
      font-weight: 500;
      @include font-size(1.125rem);
    }
    .pagination .page-item.active .page-link {
      box-shadow: none;
      background-color: $primary;
    }
  }
}

.sr-only {
  display: none;
}

.external-link {
  @include font-size(0.75rem);
  font-weight: 500;
  letter-spacing: 0.2px;
  display: inline-block;
}

//package-departure
.package-departure {
  .date-list {
    @include media-breakpoint-up(xl) {
      border-right: 2px solid $border-color;
      padding-right: $grid-gutter-width;
      margin-right: $grid-gutter-width;
      height: 100%;
    }
    @include media-breakpoint-down(xl) {
      border-bottom: 2px solid $border-color;
      padding-bottom: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;
    }
    ul {
      display: flex;
      margin: -7.5px;
      list-style: none;
      padding: 0;
      flex-wrap: wrap;
      > li {
        padding: 7.5px;
        @include font-size(0.9375rem);
        font-weight: 500;
        color: $text-muted;
      }
    }
  }
}

//data-table
.data-table {
  .btn-sm {
    padding: 3px 12px;
    border: 0;
    border-radius: 50rem;
    font-weight: 600;
    white-space: nowrap;
    @include font-size(0.75rem);
    i {
      line-height: 0;
      vertical-align: middle;
      margin-right: 5px;
    }

    &.btn-danger {
      background-color: rgba($danger, 0.12);
      color: $danger;
      &:hover {
        background-color: $danger;
        color: $white;
      }
    }
    &.btn-primary {
      background-color: rgba($primary, 0.12);
      color: $primary;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
    &.btn-warning {
      background-color: rgba($warning, 0.12);
      color: $warning;
      &:hover {
        background-color: $warning;
        color: $white;
      }
    }
  }
}

//ant-tabs-nav
.common-module {
  .ant-tabs-tab {
    font-weight: 600;
    @include font-size(0.9375rem);
    &:hover {
      color: $primary;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary;
  }
  .ant-tabs-ink-bar {
    background-color: $primary;
  }
}

.btn-icon{
  @include font-size(2rem);
  line-height: 0;
  margin-right: 15px;
  color: $primary;
}

//itineraries
.itineraries{
  .ant-collapse{
    &+.ant-collapse{
    }
    .ant-collapse-header{
      .ant-collapse-expand-icon{
        .ant-collapse-arrow{
          height: 24px;
          width: 24px;
          color: $primary;
          border: 2px solid $primary;
          border-radius: 50rem;
          @include font-size(1.125rem);
          line-height: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

      }
    }
    &.ant-collapse-item-active{}
  }
}

//page-header
.page-header{
  display: flex;
  line-height: 1;
  >.icon{
    flex: 0 0 32px;
    max-width: 32px;
    i{
      transition: 0.3s ease all;
      cursor: pointer;
      &:hover{
        transform: scale(1.1, 1.1);
      }
    }
  }
  .text{
    padding-left: 15px;
    h1{
      @include font-size(1.5rem);
      margin-bottom: 0;
      line-height: 1.2;
    }

  }
  &+.ant-tabs{
    @include font-size(.8375rem);
    >.ant-tabs-nav{
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 47px;
    }
  }
  &+.custom-form{
    padding-top: 24px;
  }
}

//btn-save
.btn-save{
  i{
    margin-right: 5px;
    display: inline-block;
  }
}